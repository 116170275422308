import { render, staticRenderFns } from "./Address.vue?vue&type=template&id=35663ec4&"
import script from "./Address.vue?vue&type=script&lang=js&"
export * from "./Address.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("F:\\datanino.com\\mydivorce.net\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('35663ec4')) {
      api.createRecord('35663ec4', component.options)
    } else {
      api.reload('35663ec4', component.options)
    }
    module.hot.accept("./Address.vue?vue&type=template&id=35663ec4&", function () {
      api.rerender('35663ec4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/Address/Address.vue"
export default component.exports