<template>
    <div>
        <h3>Information about yourself</h3>

        <div class="form-group">
            <label for="is_husband">Are you Husband or Wife?</label> <br />
            <div class="form-check form-check-inline">
                <input
                    id="is_husband_true"
                    name="is_husband"
                    class="form-check-input"
                    type="radio"
                    v-model="is_husband"
                    :value="1"
                    required
                />
                <label class="form-check-label" for="is_husband_true"
                    >Husband</label
                >
            </div>

            <div class="form-check form-check-inline">
                <input
                    id="is_husband_false"
                    name="is_husband"
                    class="form-check-input"
                    type="radio"
                    v-model="is_husband"
                    :value="0"
                    required
                />
                <label class="form-check-label" for="is_husband_false"
                    >Wife</label
                >
            </div>

            <div class="form-group" style="margin-top:8px">
                <label for="phone_number">What's your date of birth?</label>

                <datepicker
                    name="date_of_birth"
                    v-model="date_of_birth"
                    :value="date_of_birth"
                    required
                />
            </div>

            <div class="form-group">
                <label for="phone_number">What's your race?</label>
                <input
                    id="race"
                    name="race"
                    class="form-control"
                    type="text"
                    v-model="race"
                    required
                />
            </div>
            <div class="form-group">
                <label for="phone_number">Phone Number:</label>
                <input
                    id="phone_number"
                    name="phone_number"
                    class="form-control"
                    type="text"
                    v-model="formattedPhoneValue"
                    required
                    @keyup="focusOut"
                    @blur="focusOut"
                />
            </div>
            <div class="form-group">
                <label for="address">What's your Address?:</label>
                <input
                    id="home_address_input_address"
                    name="home_address_input_address"
                    class="form-control"
                    type="text"
                    v-model="home_address_input_address"
                    required
                    ref="autocomplete"
                />
            </div>
            <div class="form-group">
                <label>Do you rent or own this house?</label> <br />
                <div class="form-check form-check-inline">
                    <input
                        id="has_home_false"
                        name="has_home"
                        class="form-check-input"
                        type="radio"
                        v-model="has_home"
                        :value="0"
                        required
                    />
                    <label class="form-check-label" for="has_home_false"
                        >Rent</label
                    >
                </div>
                <div class="form-check form-check-inline">
                    <input
                        id="has_home_true"
                        name="has_home"
                        class="form-check-input"
                        type="radio"
                        v-model="has_home"
                        :value="1"
                        required
                    />
                    <label class="form-check-label" for="has_home_true"
                        >Own</label
                    >
                </div>
            </div>
            <div class="form-group" v-if="has_home">
                <label>Was this property acquired during marriage?</label>
                <br />
                <div class="form-check form-check-inline">
                    <input
                        id="home_acquired_during_marriage_true"
                        name="home_acquired_during_marriage"
                        class="form-check-input"
                        type="radio"
                        v-model="home_acquired_during_marriage"
                        :value="1"
                        required
                    />
                    <label
                        class="form-check-label"
                        for="home_acquired_during_marriage_true"
                        >Yes</label
                    >
                </div>
                <div class="form-check form-check-inline">
                    <input
                        id="home_acquired_during_marriage_false"
                        name="home_acquired_during_marriage"
                        class="form-check-input"
                        type="radio"
                        v-model="home_acquired_during_marriage"
                        :value="0"
                        required
                    />
                    <label
                        class="form-check-label"
                        for="home_acquired_during_marriage_false"
                        >No</label
                    >
                </div>
            </div>
        </div>

        <input
            type="hidden"
            name="home_address_street_number"
            v-model="home_address_street_number"
        />
        <input
            type="hidden"
            name="home_address_street"
            v-model="home_address_street"
        />
        <input
            type="hidden"
            name="home_address_city"
            v-model="home_address_city"
        />
        <input
            type="hidden"
            name="home_address_state"
            v-model="home_address_state"
        />
        <input
            type="hidden"
            name="home_address_zip_code"
            v-model="home_address_zip_code"
        />
        <input
            type="hidden"
            name="home_address_county"
            v-model="home_address_county"
        />

        <button type="submit" class="btn btn-primary btn-lg btn-block">
            Next Step
        </button>
    </div>
</template>

<script>
export default {
    props: {
        customer: Object,
        divorcecase: Object
    },
    data() {
        return {
            phoneValue: 0,
            formattedPhoneValue: null,
            preventNextIteration: false,
            phone_number: null,
            is_husband: null,
            has_home: null,
            home_acquired_during_marriage: null,
            home_address_input_address: null,
            home_address_street_number: null,
            home_address_street: null,
            home_address_city: null,
            home_address_state: null,
            home_address_zip_code: null,
            home_address_county: null,
            date_of_birth: null,
            race: null
        };
    },
    mounted() {
        const autocomplete = new google.maps.places.Autocomplete(
            this.$refs.autocomplete,
            { types: ["geocode"], componentRestrictions: { country: "us" } }
        );

        autocomplete.addListener("place_changed", () => {
            let place = autocomplete.getPlace();

            this.home_address_input_address = place.formatted_address;

            const addressComponents = place.address_components;

            addressComponents.forEach((addressComponent, index) => {
                let googleField = addressComponent.types[0];

                if (googleField === "street_number") {
                    this.home_address_street_number =
                        addressComponent.long_name;
                }

                if (googleField === "route") {
                    this.home_address_street = addressComponent.long_name;
                }
                if (googleField === "sub_locality") {
                    this.home_address_city = addressComponent.long_name;
                }
                if (googleField === "locality") {
                    this.home_address_city = addressComponent.long_name;
                }
                if (googleField === "administrative_area_level_1") {
                    this.home_address_state = addressComponent.long_name;
                }
                if (googleField === "postal_code") {
                    this.home_address_zip_code = addressComponent.long_name;
                }

                if (googleField === "administrative_area_level_2") {
                    this.home_address_county = addressComponent.long_name;
                }
            });
        });

        this.is_husband = this.divorcecase.is_husband;
        this.formattedPhoneValue = this.customer.phone_number;
        this.phone_number = this.customer.phone_number;
        this.has_home = this.divorcecase.has_home;
        this.home_acquired_during_marriage = this.divorcecase.home_acquired_during_marriage;
        this.date_of_birth = this.customer.date_of_birth;
        this.race = this.customer.race;

        this.home_address_input_address = this.divorcecase.home_address_input_address;
        this.home_address_street_number = this.divorcecase.home_address_street_number;
        this.home_address_street = this.divorcecase.home_address_street;
        this.home_address_city = this.divorcecase.home_address_city;
        this.home_address_state = this.divorcecase.home_address_state;
        this.home_address_county = this.divorcecase.home_address_county;
        this.home_address_zip_code = this.divorcecase.home_address_zip_code;
    },
    methods: {
        focusOut(event) {
            if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
                this.preventNextIteration = true;
                return;
            }
            if (this.preventNextIteration) {
                this.preventNextIteration = false;
                return;
            }
            this.phoneValue = this.formattedPhoneValue
                .replace(/-/g, "")
                .match(/(\d{1,10})/g)[0];

            // Format display value based on calculated currencyValue
            this.formattedPhoneValue = this.phoneValue.replace(
                /(\d{1,3})(\d{1,3})(\d{1,4})/g,
                "$1-$2-$3"
            );
        }
    }
};
</script>
