<template>
    <div>
        <h3>Occupation</h3>
        <div class="form-group row">
            <label for="occupation" class="col-sm-4 col-form-label"
                >Occupation</label
            >
            <div class="col-sm-8">
                <input
                    id="occupation"
                    name="occupation"
                    class="form-control"
                    type="text"
                    v-model="occupation"
                    required
                />
            </div>
        </div>

        <div class="form-group row">
            <label for="employer_name" class="col-sm-4 col-form-label"
                >Employer Name</label
            >
            <div class="col-sm-8">
                <input
                    id="employer_name"
                    name="employer_name"
                    class="form-control"
                    type="text"
                    v-model="employer_name"
                    required
                />
            </div>
        </div>

        <div class="form-group">
            <label for="employer_location_input_address" class="col-form-label"
                >Employer Location
            </label>
            <div>
                <input
                    name="employer_location_input_address"
                    class="form-control"
                    type="text"
                    v-model="employer_location_input_address"
                    required
                    ref="autocomplete"
                />
            </div>
        </div>

        <input
            type="hidden"
            name="employer_location_street_number"
            v-model="employer_location_street_number"
        />
        <input
            type="hidden"
            name="employer_location_street"
            v-model="employer_location_street"
        />
        <input
            type="hidden"
            name="employer_location_city"
            v-model="employer_location_city"
        />
        <input
            type="hidden"
            name="employer_location_state"
            v-model="employer_location_state"
        />
        <input
            type="hidden"
            name="employer_location_zip_code"
            v-model="employer_location_zip_code"
        />
        <input
            type="hidden"
            name="employer_location_county"
            v-model="employer_location_county"
        />

        <button type="submit" class="btn btn-primary btn-lg btn-block">
            Next Step
        </button>
    </div>
</template>

<script>
const locationAttributes = {
    street_number: "street_number",
    route: "street",
    sub_locality: "city",
    locality: "city",
    administrative_area_level_1: "state",
    postal_code: "zip_code",
    administrative_area_level_2: "county",
    administrative_area_level_3: "city",
    country: "country"
};

export default {
    props: {
        customer: Object,
        divorcecase: Object
    },
    data() {
        return {
            occupation: null,
            employer_name: null,
            employer_location_input_address: null,
            employer_location_street_number: null,
            employer_location_street: null,
            employer_location_city: null,
            employer_location_state: null,
            employer_location_zip_code: null,
            employer_location_county: null
        };
    },
    mounted() {
        const autocomplete = new google.maps.places.Autocomplete(
            this.$refs.autocomplete,
            { componentRestrictions: { country: "us" } }
        );

        autocomplete.addListener("place_changed", () => {
            let place = autocomplete.getPlace();

            this.employer_location_input_address = place.formatted_address;

            const addressComponents = place.address_components;

            addressComponents.forEach((addressComponent, index) => {
                let googleField = addressComponent.types[0];

                if (googleField === "street_number") {
                    this.employer_location_street_number =
                        addressComponent.long_name;
                }

                if (googleField === "route") {
                    this.employer_location_street = addressComponent.long_name;
                }
                if (googleField === "sub_locality") {
                    this.employer_location_city = addressComponent.long_name;
                }
                if (googleField === "locality") {
                    this.employer_location_city = addressComponent.long_name;
                }
                if (googleField === "administrative_area_level_1") {
                    this.employer_location_state = addressComponent.long_name;
                }
                if (googleField === "postal_code") {
                    this.employer_location_zip_code =
                        addressComponent.long_name;
                }

                if (googleField === "administrative_area_level_2") {
                    this.employer_location_county = addressComponent.long_name;
                }
            });
        });

        console.log(this.autocomplete);
        console.log("Component mounted.");

        this.occupation = this.divorcecase.occupation;
        this.employer_name = this.divorcecase.employer_name;

        this.employer_location_input_address = this.divorcecase.employer_location_input_address;
        this.employer_location_street_number = this.divorcecase.employer_location_street_number;
        this.employer_location_street = this.divorcecase.employer_location_street;
        this.employer_location_city = this.divorcecase.employer_location_city;
        this.employer_location_state = this.divorcecase.employer_location_state;
        this.employer_location_county = this.divorcecase.employer_location_county;
        this.employer_location_zip_code = this.divorcecase.employer_location_zip_code;
    }
};
</script>
