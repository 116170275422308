<template>
    <div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                know_spouse_location: null,
                spouse_willing_to_sign_divorce: null,
                lived_in_nj_for_1_year: null,
                with_children_under_18: null,
                email: null,
            }
        },
        mounted() {
            console.log('Component mounted.')
        }
    }
</script>
