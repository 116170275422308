var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", [_vm._v("Account Information")]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "exampleInputEmail1" } }, [
        _vm._v(
          "Please set your password so that you can access your\n            information at any time."
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-check form-check-inline" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password"
            }
          ],
          staticClass: "form-control",
          attrs: { name: "password", type: "text", required: "" },
          domProps: { value: _vm.password },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.password = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-primary btn-lg btn-block",
        attrs: { type: "submit" }
      },
      [_vm._v("\n        Next Step\n    ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }