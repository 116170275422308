<template>
    <div>
        <input
            :name="name"
            type="text"
            class="form-control"
            ref="datepicker"
            readonly
            title="datepicker"
        />
    </div>
</template>

<script>
import daterangepicker from "bootstrap-daterangepicker";
import moment from "moment";

export default {
    props: {
        name: "",
        value: {
            type: String,
            default: ""
        },
        time: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            format: this.time ? "MM/DD/YYYY h:mm A" : "MM/DD/YYYY"
        };
    },

    mounted() {
        this.open();
    },
    beforeDestroy() {
        $(this.$refs.datepicker).remove();
    },
    watch: {
        time() {
            this.format = this.time ? "MM/DD/YYYY h:mm A" : "MM/DD/YYYY";
            this.open();
        },
        value() {
            this.open();
        }
    },
    methods: {
        open() {
            let datepicker = $(this.$refs.datepicker);

            let startDate = moment();

            if (this.value != "" && this.value != null) {
                if (moment(this.value, this.format).isValid()) {
                    startDate = moment(this.value, this.format);
                } else {
                    startDate = moment(this.value);
                }
            }

            datepicker.daterangepicker(
                {
                    startDate: startDate.toDate(),
                    singleDatePicker: true,
                    timePicker: this.time,
                    timePickerIncrement: 15,
                    showDropdowns: true,
                    locale: {
                        format: this.format
                    }
                },
                (start, end, label) => {
                    this.$emit("input", start.format(this.format));
                }
            );

            this.$emit("input", startDate.format(this.format));
        }
    }
};
</script>
