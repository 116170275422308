var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "example2" }, [
    _c(
      "form",
      {
        staticClass: "sr-payment-form",
        attrs: { id: "payment-form", method: "post" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c("input", {
          attrs: { type: "hidden", name: "_token" },
          domProps: { value: _vm.csrf }
        }),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: {
              type: "submit",
              "data-tid": "elements_examples.form.pay_button"
            }
          },
          [_vm._v("\n            Pay $199.99\n        ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "error", attrs: { role: "alert" } }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "17",
                height: "17",
                viewBox: "0 0 17 17"
              }
            },
            [
              _c("path", {
                staticClass: "base",
                attrs: {
                  fill: "#000",
                  d:
                    "M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
                }
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "glyph",
                attrs: {
                  fill: "#FFF",
                  d:
                    "M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "message" })
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { "data-locale-reversible": "" } }, [
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("label", { attrs: { for: "address" } }, [_vm._v("Address")]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control",
            attrs: {
              id: "address",
              "data-tid": "elements_examples.form.address_placeholder",
              type: "text",
              required: "",
              autocomplete: "address-line1"
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-row", attrs: { "data-locale-reversible": "" } },
        [
          _c("div", { staticClass: "col-md-4" }, [
            _c("label", { attrs: { for: "city" } }, [_vm._v("City")]),
            _vm._v(" "),
            _c("input", {
              staticClass: "form-control",
              attrs: {
                id: "city",
                "data-tid": "elements_examples.form.city_placeholder",
                type: "text",
                required: "",
                autocomplete: "address-level2"
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }, [
            _c("label", { attrs: { for: "city" } }, [_vm._v("State")]),
            _vm._v(" "),
            _c("input", {
              staticClass: "form-control",
              attrs: {
                id: "example2-state",
                "data-tid": "elements_examples.form.state_placeholder",
                type: "text",
                required: "",
                autocomplete: "address-level1"
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }, [
            _c("label", { attrs: { for: "city" } }, [_vm._v("ZIP")]),
            _vm._v(" "),
            _c("input", {
              staticClass: "form-control",
              attrs: {
                id: "example2-zip",
                "data-tid": "elements_examples.form.postal_code_placeholder",
                type: "text",
                required: "",
                autocomplete: "postal-code"
              }
            })
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "city" } }, [_vm._v("Card Number")]),
        _vm._v(" "),
        _c("div", {
          staticClass: "col-md-12 form-control",
          attrs: { id: "example2-card-number" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", { attrs: { for: "city" } }, [_vm._v("Expiration")]),
        _vm._v(" "),
        _c("div", {
          staticClass: "form-control",
          attrs: { id: "example2-card-expiry" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", { attrs: { for: "city" } }, [_vm._v("CVC")]),
        _vm._v(" "),
        _c("div", {
          staticClass: "form-control",
          attrs: { id: "example2-card-cvc" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }