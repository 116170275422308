<template>
    <div>
        <h3>Legal Proceedings</h3>
        <div class="form-group">
            <label for="exampleInputEmail1"
                >Have there been any prior legal proceeding between you and your
                spouse such as domestic violence proceedings or child support?
            </label>
            <div class="form-check form-check-inline">
                <input
                    id="has_prior_proceedings_true"
                    name="has_prior_proceedings"
                    class="form-check-input"
                    type="radio"
                    v-model="has_prior_proceedings"
                    :value="1"
                    required
                />
                <label class="form-check-label" for="has_prior_proceedings_true"
                    >Yes</label
                >
            </div>
            <div class="form-check form-check-inline">
                <input
                    id="has_prior_proceedings_false"
                    name="has_prior_proceedings"
                    class="form-check-input"
                    type="radio"
                    v-model="has_prior_proceedings"
                    :value="0"
                    required
                />
                <label
                    class="form-check-label"
                    for="has_prior_proceedings_false"
                    >No</label
                >
            </div>
        </div>

        <button type="submit" class="btn btn-primary btn-lg btn-block">
            Next Step
        </button>
    </div>
</template>

<script>
export default {
    props: {
        customer: Object,
        divorcecase: Object
    },
    data() {
        return {
            has_prior_proceedings: null
        };
    },
    mounted() {
        this.has_prior_proceedings = this.divorcecase.has_prior_proceedings;
    }
};
</script>
