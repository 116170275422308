<template>
    <div class="col-12">
        <div class="form-group">
            <p class="mb-0">Do you know the location of your spouse?</p>
            <div class="form-check form-check-inline">
                <input
                    name="know_spouse_location"
                    class="form-check-input"
                    type="radio"
                    v-model="know_spouse_location"
                    :value="1"
                    required
                />
                <label class="form-check-label">Yes</label>
            </div>
            <div class="form-check form-check-inline">
                <input
                    name="know_spouse_location"
                    class="form-check-input"
                    type="radio"
                    v-model="know_spouse_location"
                    :value="0"
                    required
                />
                <label class="form-check-label">No</label>
            </div>
        </div>

        <div class="form-group">
            <p class="mb-0">
                Is your spouse willing to sign divorce documents?
            </p>
            <div class="form-check form-check-inline">
                <input
                    name="spouse_willing_to_sign_divorce"
                    class="form-check-input"
                    type="radio"
                    v-model="spouse_willing_to_sign_divorce"
                    :value="1"
                    required
                />
                <label class="form-check-label">Yes</label>
            </div>
            <div class="form-check form-check-inline">
                <input
                    name="spouse_willing_to_sign_divorce"
                    class="form-check-input"
                    type="radio"
                    v-model="spouse_willing_to_sign_divorce"
                    :value="0"
                    required
                />
                <label class="form-check-label">No</label>
            </div>
        </div>

        <div class="form-group">
            <p class="mb-0">
                Have you lived continuously in New Jersey for at least one year?
            </p>
            <div class="form-check form-check-inline">
                <input
                    name="lived_in_nj_for_1_year"
                    class="form-check-input"
                    type="radio"
                    v-model="lived_in_nj_for_1_year"
                    :value="1"
                    required
                />
                <label class="form-check-label">Yes</label>
            </div>
            <div class="form-check form-check-inline">
                <input
                    name="lived_in_nj_for_1_year"
                    class="form-check-input"
                    type="radio"
                    v-model="lived_in_nj_for_1_year"
                    :value="0"
                    required
                />
                <label class="form-check-label">No</label>
            </div>
        </div>

        <div class="form-group">
            <p class="mb-0">
                Are there any children under 18 born of the marriage?
            </p>
            <div class="form-check form-check-inline">
                <input
                    name="with_children_under_18"
                    class="form-check-input"
                    type="radio"
                    v-model="with_children_under_18"
                    :value="1"
                    required
                />
                <label class="form-check-label">Yes</label>
            </div>
            <div class="form-check form-check-inline">
                <input
                    name="with_children_under_18"
                    class="form-check-input"
                    type="radio"
                    v-model="with_children_under_18"
                    :value="0"
                    required
                />
                <label class="form-check-label">No</label>
            </div>
        </div>

        <div class="form-group" v-if="with_children_under_18 === 1">
            <p class="mb-0">
                Are you and your spouse able to amicably resolve all parenting
                issues?
            </p>
            <div class="form-check form-check-inline">
                <input
                    name="can_amicably_resolve"
                    class="form-check-input"
                    type="radio"
                    v-model="can_amicably_resolve"
                    :value="1"
                    required
                />
                <label class="form-check-label">Yes</label>
            </div>
            <div class="form-check form-check-inline">
                <input
                    name="can_amicably_resolve"
                    class="form-check-input"
                    type="radio"
                    v-model="can_amicably_resolve"
                    :value="0"
                    required
                />
                <label class="form-check-label">No</label>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <input
                    placeholder="First Name"
                    class="form-control"
                    name="first_name"
                    type="text"
                    v-model="first_name"
                    required
                />
            </div>

            <div class="col">
                <input
                    placeholder="Last Name"
                    class="form-control"
                    name="last_name"
                    type="text"
                    v-model="last_name"
                    required
                />
            </div>
        </div>

        <div class="form-group"></div>

        <div class="form-group">
            <input
                placeholder="Email"
                class="form-control"
                name="email"
                type="email"
                v-model="email"
                required
            />
        </div>

        <button
            type="submit"
            class="btn btn-block text-white bg-color rounded-lg py-3 font-weight-semibold text-uppercase mt-2"
        >
            Check Eligibility
        </button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            know_spouse_location: 1,
            spouse_willing_to_sign_divorce: 1,
            lived_in_nj_for_1_year: 1,
            with_children_under_18: 0,
            can_amicably_resolve: 1,
            first_name: null,
            last_name: null,
            email: null
        };
    },
    mounted() {
        console.log("Component mounted.");
    }
};
</script>
