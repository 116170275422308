<template>
    <div>
        <h3>Account Information</h3>
        <div class="form-group">
            <label for="exampleInputEmail1"
                >Please set your password so that you can access your
                information at any time.</label
            >
            <div class="form-check form-check-inline">
                <input
                    name="password"
                    class="form-control"
                    type="text"
                    v-model="password"
                    required
                />
            </div>
        </div>
        <button type="submit" class="btn btn-primary btn-lg btn-block">
            Next Step
        </button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            password: null
        };
    },
    mounted() {
        this.autocomplete = new google.maps.places.Autocomplete(
            this.$refs.autocomplete,
            { types: ["geocode"] }
        );

        this.autocomplete.addListener("place_changed", () => {
            let place = this.autocomplete.getPlace();

            console.log(place);
        });

        console.log(this.autocomplete);
        console.log("Component mounted.");
    }
};
</script>
