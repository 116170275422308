<template>
    <div>
        <h3>Information about your spouse</h3>
        <div class="form-group row">
            <label for="spouse_first_name" class="col-sm-4 col-form-label"
                >First Name</label
            >
            <div class="col-sm-8">
                <input
                    id="spouse_first_name"
                    name="spouse_first_name"
                    class="form-control"
                    type="text"
                    v-model="spouse_first_name"
                    required
                />
            </div>
        </div>
        <div class="form-group row">
            <label for="spouse_middle_name" class="col-sm-4 col-form-label"
                >Middle Name</label
            >
            <div class="col-sm-8">
                <input
                    id="spouse_middle_name"
                    name="spouse_middle_name"
                    class="form-control"
                    type="text"
                    v-model="spouse_middle_name"
                />
            </div>
        </div>
        <div class="form-group row">
            <label for="spouse_last_name" class="col-sm-4 col-form-label">
                Last Name
            </label>
            <div class="col-sm-8">
                <input
                    id="spouse_last_name"
                    name="spouse_last_name"
                    class="form-control"
                    type="text"
                    v-model="spouse_last_name"
                    required
                />
            </div>
        </div>

        <div class="form-group row">
            <label for="spouse_date_of_birth" class="col-sm-4 col-form-label">
                Date of Birth
            </label>
            <div class="col-sm-8">
                <datepicker
                    name="spouse_date_of_birth"
                    v-model="spouse_date_of_birth"
                    :value="spouse_date_of_birth"
                    required
                />
            </div>
        </div>

        <div class="form-group row">
            <label for="spouse_race" class="col-sm-4 col-form-label">
                Race
            </label>
            <div class="col-sm-8">
                <input
                    id="spouse_race"
                    name="spouse_race"
                    class="form-control"
                    type="text"
                    v-model="spouse_race"
                    required
                />
            </div>
        </div>

        <div class="form-group row">
            <label for="spouse_sex" class="col-sm-4 col-form-label">
                Sex
            </label>
            <div class="col-sm-8">
                <div class="form-check form-check-inline">
                    <input
                        id="spouse_sex_male"
                        name="spouse_sex"
                        class="form-check-input"
                        type="radio"
                        v-model="spouse_sex"
                        value="M"
                        required
                    />
                    <label class="form-check-label" for="spouse_sex_male"
                        >M</label
                    >
                </div>
                <div class="form-check form-check-inline">
                    <input
                        id="spouse_sex_female"
                        name="spouse_sex"
                        class="form-check-input"
                        type="radio"
                        v-model="spouse_sex"
                        value="F"
                        required
                    />
                    <label class="form-check-label" for="spouse_sex_female"
                        >F</label
                    >
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label for="spouse_attorney" class="col-sm-4 col-form-label">
                Spouse Attorney
            </label>
            <div class="col-sm-8">
                <input
                    id="spouse_attorney"
                    name="spouse_attorney"
                    class="form-control"
                    type="text"
                    v-model="spouse_attorney"
                    required
                />
            </div>
        </div>

        <button type="submit" class="btn btn-primary btn-lg btn-block">
            Next Step
        </button>
    </div>
</template>

<script>
export default {
    props: {
        customer: Object,
        divorcecase: Object
    },
    data() {
        return {
            spouse_first_name: null,
            spouse_middle_name: null,
            spouse_last_name: null,
            spouse_date_of_birth: null,
            spouse_sex: null,
            spouse_race: null
        };
    },
    mounted() {
        this.spouse_first_name = this.divorcecase.spouse_first_name;
        this.spouse_middle_name = this.divorcecase.spouse_middle_name;
        this.spouse_last_name = this.divorcecase.spouse_last_name;
        this.spouse_date_of_birth = this.divorcecase.spouse_date_of_birth;
        this.spouse_sex = this.divorcecase.spouse_sex;
        this.spouse_race = this.divorcecase.spouse_race;
        this.spouse_attorney = this.divorcecase.spouse_attorney;
    }
};
</script>
