var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", [_vm._v("Current Home")]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "exampleInputEmail1" } }, [
        _vm._v("Do you both live together or separate at this time?\n        ")
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "form-check form-check-inline" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.live_together,
              expression: "live_together"
            }
          ],
          staticClass: "form-check-input",
          attrs: { name: "live_together", type: "radio", required: "" },
          domProps: { value: 1, checked: _vm._q(_vm.live_together, 1) },
          on: {
            change: function($event) {
              _vm.live_together = 1
            }
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "form-check-label",
            attrs: { for: "inlineCheckbox1" }
          },
          [_vm._v("Together")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-check form-check-inline" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.live_together,
              expression: "live_together"
            }
          ],
          staticClass: "form-check-input",
          attrs: { name: "live_together", type: "radio", required: "" },
          domProps: { value: 0, checked: _vm._q(_vm.live_together, 0) },
          on: {
            change: function($event) {
              _vm.live_together = 0
            }
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "form-check-label",
            attrs: { for: "inlineCheckbox2" }
          },
          [_vm._v("Separate")]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.live_together === 0,
            expression: "live_together === 0"
          }
        ],
        staticClass: "form-group"
      },
      [
        _c("label", { attrs: { for: "exampleInputEmail1" } }, [
          _vm._v("What is your spouse’s address?")
        ]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.spouse_address_input_address,
                expression: "spouse_address_input_address"
              }
            ],
            ref: "autocomplete",
            staticClass: "form-control",
            attrs: {
              name: "spouse_address_input_address",
              type: "text",
              required: _vm.live_together === 0
            },
            domProps: { value: _vm.spouse_address_input_address },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.spouse_address_input_address = $event.target.value
              }
            }
          })
        ])
      ]
    ),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.spouse_address_street_number,
          expression: "spouse_address_street_number"
        }
      ],
      attrs: { type: "hidden", name: "spouse_address_street_number" },
      domProps: { value: _vm.spouse_address_street_number },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.spouse_address_street_number = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.spouse_address_street,
          expression: "spouse_address_street"
        }
      ],
      attrs: { type: "hidden", name: "spouse_address_street" },
      domProps: { value: _vm.spouse_address_street },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.spouse_address_street = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.spouse_address_city,
          expression: "spouse_address_city"
        }
      ],
      attrs: { type: "hidden", name: "spouse_address_city" },
      domProps: { value: _vm.spouse_address_city },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.spouse_address_city = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.spouse_address_state,
          expression: "spouse_address_state"
        }
      ],
      attrs: { type: "hidden", name: "spouse_address_state" },
      domProps: { value: _vm.spouse_address_state },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.spouse_address_state = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.spouse_address_zip_code,
          expression: "spouse_address_zip_code"
        }
      ],
      attrs: { type: "hidden", name: "spouse_address_zip_code" },
      domProps: { value: _vm.spouse_address_zip_code },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.spouse_address_zip_code = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.spouse_address_county,
          expression: "spouse_address_county"
        }
      ],
      attrs: { type: "hidden", name: "spouse_address_county" },
      domProps: { value: _vm.spouse_address_county },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.spouse_address_county = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-primary btn-lg btn-block",
        attrs: { type: "submit" }
      },
      [_vm._v("\n        Next Step\n    ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }