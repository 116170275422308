<template>
    <div class="example2">
        <form
            id="payment-form"
            method="post"
            class="sr-payment-form"
            v-on:submit.prevent="onSubmit"
        >
            <input type="hidden" name="_token" :value="csrf" />
            <div data-locale-reversible>
                <div class="form-row">
                    <div class="col-md-12">
                        <label for="address">Address</label>
                        <input
                            id="address"
                            data-tid="elements_examples.form.address_placeholder"
                            class="form-control"
                            type="text"
                            required=""
                            autocomplete="address-line1"
                        />
                    </div>
                </div>
                <div class="form-row" data-locale-reversible>
                    <div class="col-md-4">
                        <label for="city">City</label>
                        <input
                            id="city"
                            data-tid="elements_examples.form.city_placeholder"
                            class="form-control"
                            type="text"
                            required=""
                            autocomplete="address-level2"
                        />
                    </div>
                    <div class="col-md-4">
                        <label for="city">State</label>
                        <input
                            id="example2-state"
                            data-tid="elements_examples.form.state_placeholder"
                            class="form-control"
                            type="text"
                            required=""
                            autocomplete="address-level1"
                        />
                    </div>
                    <div class="col-md-4">
                        <label for="city">ZIP</label>
                        <input
                            id="example2-zip"
                            data-tid="elements_examples.form.postal_code_placeholder"
                            class="form-control"
                            type="text"
                            required=""
                            autocomplete="postal-code"
                        />
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-12">
                    <label for="city">Card Number</label>
                    <div
                        id="example2-card-number"
                        class="col-md-12 form-control"
                    ></div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6">
                    <label for="city">Expiration</label>

                    <div id="example2-card-expiry" class="form-control"></div>
                </div>

                <div class="col-md-6">
                    <label for="city">CVC</label>
                    <div id="example2-card-cvc" class="form-control"></div>
                </div>
            </div>
            <button
                type="submit"
                class="btn btn-primary"
                data-tid="elements_examples.form.pay_button"
            >
                Pay $199.99
            </button>
            <div class="error" role="alert">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                >
                    <path
                        class="base"
                        fill="#000"
                        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
                    ></path>
                    <path
                        class="glyph"
                        fill="#FFF"
                        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
                    ></path>
                </svg>
                <span class="message"></span>
            </div>
        </form>
    </div>
</template>

<script>
// Create a Stripe client.
const stripe = Stripe(
    "pk_test_51GtZ55JREh9ItxEpGJvYkDQj7XmKeG7ywxf42NxihzS6GhbvEERy32VLghSd8qyACvRW6qBXkurWiHwBPYzcCiFB00QDfT6gNn"
);

// Create an instance of Elements.
const elements = stripe.elements();

export default {
    data() {
        return {
            card: null,
            csrf: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content")
        };
    },
    mounted() {
        // Custom styling can be passed to options when creating an Element.
        // (Note that this demo uses a wider set of styles than the guide below.)
        var elementStyles = {
            base: {
                color: "#32325D",
                fontWeight: 500,
                fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
                fontSize: "16px",
                fontSmoothing: "antialiased",

                "::placeholder": {
                    color: "#CFD7DF"
                }
            },
            invalid: {
                color: "#E25950",

                "::placeholder": {
                    color: "#FFCCA5"
                }
            }
        };

        var elementClasses = {
            focus: "focused",
            empty: "empty",
            invalid: "invalid"
        };

        var inputs = document.querySelectorAll(".example2 .input");

        Array.prototype.forEach.call(inputs, function(input) {
            input.addEventListener("focus", function() {
                input.classList.add("focused");
            });
            input.addEventListener("blur", function() {
                input.classList.remove("focused");
            });
            input.addEventListener("keyup", function() {
                if (input.value.length === 0) {
                    input.classList.add("empty");
                } else {
                    input.classList.remove("empty");
                }
            });
        });

        // Create an instance of the card Element.
        const cardNumber = (this.card = elements.create("cardNumber", {
            style: elementStyles,
            classes: elementClasses
        }));

        this.card.mount("#example2-card-number");

        var cardExpiry = elements.create("cardExpiry", {
            style: elementStyles,
            classes: elementClasses
        });
        cardExpiry.mount("#example2-card-expiry");

        var cardCvc = elements.create("cardCvc", {
            style: elementStyles,
            classes: elementClasses
        });
        cardCvc.mount("#example2-card-cvc");

        this.registerElements([cardNumber, cardExpiry, cardCvc], "example2");
    },
    methods: {
        registerElements(elements, exampleName) {
            var formClass = "." + exampleName;
            var example = document.querySelector(formClass);

            var form = example.querySelector("form");
            var error = form.querySelector(".error");
            var errorMessage = error.querySelector(".message");

            function enableInputs() {
                Array.prototype.forEach.call(
                    form.querySelectorAll(
                        "input[type='text'], input[type='email'], input[type='tel']"
                    ),
                    function(input) {
                        input.removeAttribute("disabled");
                    }
                );
            }

            function disableInputs() {
                Array.prototype.forEach.call(
                    form.querySelectorAll(
                        "input[type='text'], input[type='email'], input[type='tel']"
                    ),
                    function(input) {
                        input.setAttribute("disabled", "true");
                    }
                );
            }

            function triggerBrowserValidation() {
                // The only way to trigger HTML5 form validation UI is to fake a user submit
                // event.
                var submit = document.createElement("input");
                submit.type = "submit";
                submit.style.display = "none";
                form.appendChild(submit);
                submit.click();
                submit.remove();
            }

            // Listen for errors from each Element, and show error messages in the UI.
            var savedErrors = {};
            elements.forEach(function(element, idx) {
                element.on("change", function(event) {
                    if (event.error) {
                        error.classList.add("visible");
                        savedErrors[idx] = event.error.message;
                        errorMessage.innerText = event.error.message;
                    } else {
                        savedErrors[idx] = null;

                        // Loop over the saved errors and find the first one, if any.
                        var nextError = Object.keys(savedErrors)
                            .sort()
                            .reduce(function(maybeFoundError, key) {
                                return maybeFoundError || savedErrors[key];
                            }, null);

                        if (nextError) {
                            // Now that they've fixed the current error, show another one.
                            errorMessage.innerText = nextError;
                        } else {
                            // The user fixed the last error; no more errors.
                            error.classList.remove("visible");
                        }
                    }
                });
            });

            // Listen on the form's 'submit' handler...
            form.addEventListener("submit", function(e) {
                e.preventDefault();

                // Trigger HTML5 validation UI on the form if any of the inputs fail
                // validation.
                var plainInputsValid = true;
                Array.prototype.forEach.call(
                    form.querySelectorAll("input"),
                    function(input) {
                        if (input.checkValidity && !input.checkValidity()) {
                            plainInputsValid = false;
                            return;
                        }
                    }
                );
                if (!plainInputsValid) {
                    triggerBrowserValidation();
                    return;
                }

                // Show a loading screen...
                example.classList.add("submitting");

                // Disable all inputs.
                disableInputs();

                // Gather additional customer data we may have collected in our form.
                var name = form.querySelector("#" + exampleName + "-name");
                var address1 = form.querySelector(
                    "#" + exampleName + "-address"
                );
                var city = form.querySelector("#" + exampleName + "-city");
                var state = form.querySelector("#" + exampleName + "-state");
                var zip = form.querySelector("#" + exampleName + "-zip");
                var additionalData = {
                    name: name ? name.value : undefined,
                    address_line1: address1 ? address1.value : undefined,
                    address_city: city ? city.value : undefined,
                    address_state: state ? state.value : undefined,
                    address_zip: zip ? zip.value : undefined
                };

                // Use Stripe.js to create a token. We only need to pass in one Element
                // from the Element group in order to create a token. We can also pass
                // in the additional customer data we collected in our form.
                stripe
                    .createToken(elements[0], additionalData)
                    .then(function(result) {
                        // Stop loading!
                        example.classList.remove("submitting");

                        if (result.token) {
                            // If we received a token, show the token ID.
                            example.querySelector(".token").innerText =
                                result.token.id;
                            example.classList.add("submitted");
                        } else {
                            // Otherwise, un-disable inputs.
                            enableInputs();
                        }
                    });
            });
        },

        onSubmit() {
            stripe.createToken(this.card).then(function(result) {
                if (result.error) {
                    // Inform the user if there was an error.
                    //   var errorElement = document.getElementById("card-errors");
                    //  errorElement.textContent = result.error.message;
                } else {
                    // Send the token to your server.
                    stripeTokenHandler(result.token);
                }
            });

            // Submit the form with the token ID.
            function stripeTokenHandler(token) {
                // Insert the token ID into the form so it gets submitted to the server
                var form = document.getElementById("payment-form");
                var hiddenInput = document.createElement("input");
                hiddenInput.setAttribute("type", "hidden");
                hiddenInput.setAttribute("name", "stripeToken");
                hiddenInput.setAttribute("value", token.id);
                form.appendChild(hiddenInput);

                // Submit the form
                form.submit();
            }
        }
    }
};
</script>
