<template>
    <div>
        <h3>Information About Your Marriage</h3>
        <div class="form-group">
            <label for="marriage_date">Marriage Date</label>

            <datepicker
                name="marriage_date"
                v-model="marriage_date"
                :value="marriage_date"
                required
            />
        </div>
        <div class="form-group">
            <label for="marriage_city">In what city were you married?</label>

            <input
                id="marriage_city_input"
                name="marriage_city_input"
                class="form-control"
                type="text"
                v-model="marriage_city_input"
                required
                ref="autocomplete"
                autocomplete="false"
            />

            <input type="hidden" name="marriage_city" v-model="marriage_city" />
            <input
                type="hidden"
                name="marriage_county"
                v-model="marriage_county"
            />
        </div>
        <div class="form-group">
            <label for="exampleInputEmail1"
                >Do you have children with your current wife?
            </label>
            <div class="form-check form-check-inline">
                <input
                    name="has_children"
                    class="form-check-input"
                    type="radio"
                    v-model="has_children"
                    :value="1"
                    required
                />
                <label class="form-check-label" for="inlineCheckbox1"
                    >Yes</label
                >
            </div>
            <div class="form-check form-check-inline">
                <input
                    name="has_children"
                    class="form-check-input"
                    type="radio"
                    v-model="has_children"
                    :value="0"
                    required
                />
                <label class="form-check-label" for="inlineCheckbox2">No</label>
            </div>
        </div>

        <div v-if="has_children">
            <h3>Children Information</h3>
            <div>
                <div v-for="(child, index) in children">
                    <div class="form-group row">
                        <label
                            :for="`children[${index}][first_name]`"
                            class="col-sm-4 col-form-label"
                            >First Name</label
                        >
                        <div class="col-sm-8">
                            <input
                                :id="`children[${index}][first_name]`"
                                :name="`children[${index}][first_name]`"
                                type="text"
                                class="form-control"
                                v-model="child.first_name"
                                required
                            />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label
                            :for="`children[${index}][first_name]`"
                            class="col-sm-4 col-form-label"
                            >Last Name</label
                        >
                        <div class="col-sm-8">
                            <input
                                :name="`children[${index}][last_name]`"
                                type="text"
                                class="form-control"
                                v-model="child.last_name"
                                required
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label
                            :for="`children[${index}][date_of_birth]`"
                            class="col-sm-4 col-form-label"
                            >Date of Birth</label
                        >
                        <div class="col-sm-8">
                            <datepicker
                                :id="`children[${index}][date_of_birth]`"
                                :name="`children[${index}][date_of_birth]`"
                                v-model="child.date_of_birth"
                                :value="child.date_of_birth"
                                required
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label
                            :for="`children[${index}][race]`"
                            class="col-sm-4 col-form-label"
                            >Race</label
                        >
                        <div class="col-sm-8">
                            <input
                                :id="`children[${index}][race]`"
                                :name="`children[${index}][race]`"
                                type="text"
                                class="form-control"
                                v-model="child.race"
                                required
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label
                            :for="`children[${index}][sex]`"
                            class="col-sm-4 col-form-label"
                            >Sex</label
                        >
                        <div class="col-sm-8">
                            <div class="form-check form-check-inline">
                                <input
                                    :id="`children[${index}][sex]_male`"
                                    :name="`children[${index}][sex]`"
                                    class="form-check-input"
                                    type="radio"
                                    v-model="child.sex"
                                    value="M"
                                    required
                                />
                                <label
                                    class="form-check-label"
                                    :for="`children[${index}][sex]_male`"
                                    >M</label
                                >
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    :id="`children[${index}][sex]_female`"
                                    :name="`children[${index}][sex]`"
                                    class="form-check-input"
                                    type="radio"
                                    v-model="child.sex"
                                    value="F"
                                    required
                                />
                                <label
                                    class="form-check-label"
                                    :for="`children[${index}][sex]_female`"
                                    >F</label
                                >
                            </div>
                        </div>
                    </div>

                    <div class="form-group row col-sm-4" v-if="index > 0">
                        <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            aria-label="Close"
                            @click="children.splice(index, 1)"
                            value="Delete"
                        >
                            Delete
                        </button>
                    </div>

                    <input
                        type="hidden"
                        :name="`children[${index}][id]`"
                        :value="child.id"
                    />
                    <hr />
                </div>

                <button
                    type="button"
                    class="btn btn-secondary  btn-sm"
                    style="
    margin-bottom: 20px;
"
                    @click.prevent.stop="addChild"
                >
                    Add more children
                </button>
            </div>
        </div>

        <button type="submit" class="btn btn-primary btn-lg btn-block">
            Next Step
        </button>
    </div>
</template>

<script>
import datepicker from "bootstrap-datepicker";

const locationAttributes = {
    street_number: "street_number",
    route: "street",
    sub_locality: "city",
    locality: "city",
    administrative_area_level_1: "state",
    postal_code: "zip_code",
    administrative_area_level_2: "county",
    administrative_area_level_3: "city",
    country: "country"
};

export default {
    props: {
        customer: Object,
        divorcecase: Object
    },
    data() {
        return {
            marriage_date: null,
            marriage_city_input: null,
            marriage_city: null,
            marriage_county: null,
            has_children: 0,
            monthNames: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ],
            children: [
                {
                    first_name: "",
                    last_name: "",
                    date_of_birth: "",
                    sex: "",
                    race: ""
                }
            ]
        };
    },
    methods: {
        addChild() {
            this.children.push({
                first_name: "",
                last_name: "",
                date_of_birth: "",
                sex: "",
                race: ""
            });
        }
    },
    mounted() {
        $(".datepicker")
            .datepicker({
                autoclose: true,
                enableOnReadonly: true,
                clearBtn: true
            })
            .on("changeDate", event => {
                this.marriage_date = event.date;
            });

        const autocomplete = new google.maps.places.Autocomplete(
            this.$refs.autocomplete,
            { types: ["(cities)"], componentRestrictions: { country: "us" } }
        );

        autocomplete.addListener("place_changed", () => {
            let place = autocomplete.getPlace();

            this.marriage_city_input = place.formatted_address;

            const addressComponents = place.address_components;

            addressComponents.forEach((addressComponent, index) => {
                let googleField = addressComponent.types[0];

                if (googleField === "locality") {
                    this.marriage_city = addressComponent.long_name;
                }

                if (googleField === "administrative_area_level_2") {
                    this.marriage_county = addressComponent.long_name;
                }
            });
        });

        this.marriage_date = this.divorcecase.marriage_date;
        this.marriage_city_input = this.divorcecase.marriage_city_input;
        this.marriage_city = this.divorcecase.marriage_city;
        this.marriage_county = this.divorcecase.marriage_county;
        this.marriage_date = this.divorcecase.marriage_date;

        this.has_children = 0;

        if (this.divorcecase.children.length > 0) {
            this.has_children = 1;

            this.children = this.divorcecase.children;
        }
    }
};
</script>
