<template>
    <div>
        <h3>Current Home</h3>
        <div class="form-group">
            <label for="exampleInputEmail1"
                >Do you both live together or separate at this time?
            </label>
            <br />
            <div class="form-check form-check-inline">
                <input
                    name="live_together"
                    class="form-check-input"
                    type="radio"
                    v-model="live_together"
                    :value="1"
                    required
                />
                <label class="form-check-label" for="inlineCheckbox1"
                    >Together</label
                >
            </div>
            <div class="form-check form-check-inline">
                <input
                    name="live_together"
                    class="form-check-input"
                    type="radio"
                    v-model="live_together"
                    :value="0"
                    required
                />
                <label class="form-check-label" for="inlineCheckbox2"
                    >Separate</label
                >
            </div>
        </div>
        <div class="form-group" v-show="live_together === 0">
            <label for="exampleInputEmail1"
                >What is your spouse’s address?</label
            >
            <div>
                <input
                    name="spouse_address_input_address"
                    class="form-control"
                    type="text"
                    v-model="spouse_address_input_address"
                    :required="live_together === 0"
                    ref="autocomplete"
                />
            </div>
        </div>

        <input
            type="hidden"
            name="spouse_address_street_number"
            v-model="spouse_address_street_number"
        />
        <input
            type="hidden"
            name="spouse_address_street"
            v-model="spouse_address_street"
        />
        <input
            type="hidden"
            name="spouse_address_city"
            v-model="spouse_address_city"
        />
        <input
            type="hidden"
            name="spouse_address_state"
            v-model="spouse_address_state"
        />
        <input
            type="hidden"
            name="spouse_address_zip_code"
            v-model="spouse_address_zip_code"
        />
        <input
            type="hidden"
            name="spouse_address_county"
            v-model="spouse_address_county"
        />
        <button type="submit" class="btn btn-primary btn-lg btn-block">
            Next Step
        </button>
    </div>
</template>

<script>
export default {
    props: {
        customer: Object,
        divorcecase: Object
    },
    data() {
        return {
            live_together: null,
            spouse_address_input_address: null,
            spouse_address_street_number: null,
            spouse_address_street: null,
            spouse_address_city: null,
            spouse_address_state: null,
            spouse_address_zip_code: null,
            spouse_address_county: null
        };
    },
    mounted() {
        const autocomplete = new google.maps.places.Autocomplete(
            this.$refs.autocomplete,
            { types: ["geocode"], componentRestrictions: { country: "us" } }
        );

        autocomplete.addListener("place_changed", () => {
            let place = autocomplete.getPlace();

            this.spouse_address_input_address = place.formatted_address;

            const addressComponents = place.address_components;

            addressComponents.forEach((addressComponent, index) => {
                let googleField = addressComponent.types[0];

                if (googleField === "street_number") {
                    this.spouse_address_street_number =
                        addressComponent.long_name;
                }

                if (googleField === "route") {
                    this.spouse_address_street = addressComponent.long_name;
                }
                if (googleField === "sub_locality") {
                    this.spouse_address_city = addressComponent.long_name;
                }
                if (googleField === "sublocality_level_1") {
                    this.spouse_address_city = addressComponent.long_name;
                }

                if (googleField === "locality") {
                    this.spouse_address_city = addressComponent.long_name;
                }
                if (googleField === "administrative_area_level_1") {
                    this.spouse_address_state = addressComponent.long_name;
                }
                if (googleField === "postal_code") {
                    this.spouse_address_zip_code = addressComponent.long_name;
                }

                if (googleField === "administrative_area_level_2") {
                    this.spouse_address_county = addressComponent.long_name;
                }
            });
        });

        this.live_together = this.divorcecase.live_together;
        this.spouse_address_input_address = this.divorcecase.spouse_address_input_address;
        this.spouse_address_street_number = this.divorcecase.spouse_address_street_number;
        this.spouse_address_street = this.divorcecase.spouse_address_street;
        this.spouse_address_city = this.divorcecase.spouse_address_city;
        this.spouse_address_state = this.divorcecase.spouse_address_state;
        this.spouse_address_county = this.divorcecase.spouse_address_county;
        this.spouse_address_zip_code = this.divorcecase.spouse_address_zip_code;
    }
};
</script>
