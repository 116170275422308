var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", [_vm._v("Legal Proceedings")]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "exampleInputEmail1" } }, [
        _vm._v(
          "Have there been any prior legal proceeding between you and your\n            spouse such as domestic violence proceedings or child support?\n        "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-check form-check-inline" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.has_prior_proceedings,
              expression: "has_prior_proceedings"
            }
          ],
          staticClass: "form-check-input",
          attrs: {
            id: "has_prior_proceedings_true",
            name: "has_prior_proceedings",
            type: "radio",
            required: ""
          },
          domProps: { value: 1, checked: _vm._q(_vm.has_prior_proceedings, 1) },
          on: {
            change: function($event) {
              _vm.has_prior_proceedings = 1
            }
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "form-check-label",
            attrs: { for: "has_prior_proceedings_true" }
          },
          [_vm._v("Yes")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-check form-check-inline" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.has_prior_proceedings,
              expression: "has_prior_proceedings"
            }
          ],
          staticClass: "form-check-input",
          attrs: {
            id: "has_prior_proceedings_false",
            name: "has_prior_proceedings",
            type: "radio",
            required: ""
          },
          domProps: { value: 0, checked: _vm._q(_vm.has_prior_proceedings, 0) },
          on: {
            change: function($event) {
              _vm.has_prior_proceedings = 0
            }
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "form-check-label",
            attrs: { for: "has_prior_proceedings_false" }
          },
          [_vm._v("No")]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-primary btn-lg btn-block",
        attrs: { type: "submit" }
      },
      [_vm._v("\n        Next Step\n    ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }